import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { AgGridColumnProps } from 'ag-grid-react';
import { LYTargetData } from 'src/pages/AssortmentStrategy/TargetSetting/Criteria/Criteria.types';
import { ColDef, ValueParserParams, ValueGetterParams, ValueFormatterParams, RowNode } from 'ag-grid-community';
import { SubheaderDropdownProps } from '../Subheader/SubheaderDropdown';
import { SalesAdjustmentConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/SalesAdjustmentEditor';
import { ScrollTo } from 'src/common-ui/components/DataGrid/DataGrid';
import { SortOption } from 'src/common-ui/components/CompanionListView/CompanionListView';
import { ParamedCalc } from 'src/utils/LibraryUtils/MathUtils';
import { StyleEditConfigColumn } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.types';
import { PrintProps } from '../higherOrder/Print/Print';
import { BasicItem, BasicPivotItem } from 'src/worker/pivotWorker.types';
import { FavoriteResponseItem } from '../Subheader/Favorites/FavoritesMenu';
import { Dispatch } from 'react-redux';
import { AppState } from 'src/store';
import { ConfigurableGridGroupBySelection } from './ConfigurableGrid.slice';
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { DataApiConfig, DataApi } from 'src/services/configuration/codecs/confdefnView';
import { MassEditConfig } from '../MassEdit/MassEditv2';
import { z } from 'zod';
import {
  AssortmentMatrixComponentProps,
  ConfigurableGridViewComponentProps,
} from 'src/services/configuration/codecs/confdefnComponentProps';
import { SelectorSubheaderDropdownProps } from './ConfigurableGrid.selectors';
import { ValidValuesCache } from 'src/services/validValuesCache';
import { CoarseEditPayload } from 'src/dao/pivotClient';
import { AdornmentType } from 'src/services/configuration/codecs/viewdefns/literals';
import { ConfigurablePostAction } from 'src/services/configuration/codecs/viewdefns/general';
import { Scope } from 'src/types/Scope';
import { ViewDataState } from 'src/types/Domain';
import { Option } from '../Configure/ConfigureModal';
import { ParseResult } from '../Configure/Configure';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';

export type GridField = {
  headerName?: string;
  colId?: string;
  pinned?: boolean;
  cellClass?: string | string[];
  renderer?: string;
  hide?: boolean;
  valueParser?: (params: ValueParserParams) => any | string;
  valueGetter?: ((params: ValueGetterParams) => any) | string;
  valueFormatter?: (params: ValueFormatterParams) => string | string;
  footerValueGetter?: ((params: ValueGetterParams) => any) | string;
  editable?: boolean;
  field?: string;
  aggFunc?: string;
  dataIndex?: string;
};

export type AggOptions = 'sum' | 'min' | 'max' | 'count' | 'avg' | 'first' | 'last' | 'totalName' | 'eval';

export type ColumnConfig = TenantConfigViewItem & {
  pinned?: boolean;
  editable?: boolean;
  calculation?: string;
  inputType?: string;
  dataApi?: DataApi;
  configApi?: DataApiConfig;
  renderer?: string;
  hidden?: boolean;
  width?: number;
  children?: ColumnConfig[];
  headerName?: string;
  aggregator?: AggOptions;
  enableValue?: boolean;
};

export type TrueColDef = {
  renderer?: string;
  footerValueGetter?: () => string; // subject to extensive change. Lazy right now
} & AgGridColumnProps;

export interface ValueProps {
  config: ColumnConfig[] | null;
  rowData: BasicItem[];
}

export interface FunctionProps {
  onSave: (rowData: LYTargetData[]) => void;
  reinitialize: () => void;
  setConfigurations?: (configurations: any) => void;
}

export interface Props<S> extends ValueProps, FunctionProps {}

export type ConfigurableGridColumnDef = ColDef &
  TenantConfigViewItem &
  StyleEditConfigColumn & {
    useMassEditUpdate: boolean; // uses new endpoint for updates (currently single datapoint update only)
    postArrayAsString?: boolean;
    width?: number;
    dataApiLifecycle?: DataApiConfig;
    dataApiStore?: DataApiConfig;
    lifecycleConfig?: DataApiConfig;
    storeConfig?: DataApiConfig;
    dependentsApi?: DataApiConfig;
    cascadeGroupSelection?: boolean;
    dependents?: string[];
  };

export type ConfigurableGridDispatchProps = {
  dispatch: Dispatch<AppState>;
  setFloorsetSelection: ActionCreatorWithPayload<string>;
  setGroupBySelection: ActionCreatorWithPayload<ConfigurableGridGroupBySelection>;
  resetGroupBySelection: ActionCreatorWithoutPayload;
  onUpdateConfig: (config: any) => void;
  updateAssortmentPlan: () => void;
  onRefreshConfigurableGridData: () => void;
  submitPayload: (payload: CoarseEditPayload) => Promise<void>;
  updateConfigureSelections?(selections: Option[]): void;
};

export type ConfigurableGridValueProps = {
  identifier: string;
  leafIdProp: string;
  configLoaded: boolean;
  dataLoaded: boolean;
  favoritesList: FavoriteResponseItem[];
  groupBySelection?: ConfigurableGridGroupBySelection;
  configureOptions?: ParseResult;
  configureSelections?: Option[];
  columnDefs: ConfigurableGridColumnDef[];
  clientActionHandlers?: ClientActionHandler;
  dependentCalcs: {
    [key: string]: ParamedCalc;
  };
  massEditConfig?: MassEditConfig;
  updateCoordinateMap?: {
    [s: string]: string;
  };
  // TODO: these need to point to viewdefn validator type
  // and fix types that have been ignored trickling down
  configuratorViewDefn?: any;
  unmodifiedViewDefn?: any;
  gridRowHeight?: number;
  groupRowHeight?: number;
  defaultCompanionSortField?: string;
  companionSortOptions: SortOption[];
  groupByDropdownProps?: SelectorSubheaderDropdownProps;
  floorsetDropdownProps?: SelectorSubheaderDropdownProps;
  data: BasicPivotItem[];
  topAttributesData: BasicPivotItem | undefined;
  salesAdjustmentConfig?: SalesAdjustmentConfig;
  flowStatus: number[];
  search: string;
  showPublish?: boolean;
  hideUnpublish?: boolean;
  adornments: AdornmentType[];
  publishText?: string;
  publishAttribute?: string;
  configurablePostAction: ConfigurablePostAction;
  scopeSelection: Scope;
  viewDataState: ViewDataState;
  showUndo: boolean;
  isWorklistActive: boolean;
};

export type ConfigurableGridProps = ConfigurableGridOwnProps &
  ConfigurableGridDispatchProps &
  ConfigurableGridValueProps &
  StyleDetailsPopoverProps;

export type ConfigurableGridOwnProps =
  | ConfigurableGridOwnPropsWithoutIdentityProps
  | ConfigurableGridOwnPropsWithIdentityProps;

type ConfigurableGridOwnPropsWithoutIdentityProps = z.infer<typeof AssortmentMatrixComponentProps> &
  PrintProps & {
    identityField: string;
    showExtraRow: boolean;
  };

type ConfigurableGridOwnPropsWithIdentityProps = z.infer<typeof ConfigurableGridViewComponentProps> &
  PrintProps & {
    identityField: null;
    showExtraRow: boolean;
  };

export type ConfigurableGridState = {
  /** used to signal to MassEdit that the grid is ready */
  gridRdyCnt: number;
  selectedIndex?: number;
  selectedId?: string;
  gridScrollTo?: ScrollTo;
  companionScrollTo?: ScrollTo;
  companionCollapsed: boolean;
  companionSortDirection: 'asc' | 'desc';
  companionSortField?: string;
  activeStyleColor: string;
  subheaderDropdownConfig?: SubheaderDropdownProps[];
  favoritesList?: FavoriteResponseItem[];
  groupByIndexTemp?: number;
  notificationModal: boolean;
  massEditModal: boolean;
  actionType: 'publish' | 'unpublish' | undefined;
  selectedItems: BasicItem[];
  validValuesCache: ValidValuesCache;
  configureIsOpen?: boolean;
  currentConfigureSelections?: Option[];
};

export type ConfigurableGridConfigItem = StyleEditConfigColumn & {
  width?: number;
  dataApiLifecycle?: DataApiConfig;
  dataApiStore?: DataApiConfig;
  lifecycleConfig?: DataApiConfig;
  storeConfig?: DataApiConfig;
  dependentsApi?: DataApiConfig;
  postArrayAsString?: boolean;
  cascadeGroupSelection?: boolean;
  tabIndex?: number;
  editableByCalc?: string;
  dependents?: string[];
};

export type ClientActionHandler = {
  [clientHandler: string]: string[]; // value is array of dataIndices that require clientHandler logic
};

export type ClientActionHandlersConfig = {
  [action: string]: ClientActionHandler;
};

/**
 * State required to be implemented by any popup editors that are enabled for aggregate cell edits
 * */
export interface ConfigurableGridAggCellEditorState {
  valueChosen: boolean;
}

export interface MassColumnUpdateParams {
  value: string;
  dataIndex: string;
  nodes: RowNode[];
}

export enum AsyncCellState {
  Idle = 'Idle',
  Processing = 'Processing',
  Redecorating = 'Redecorating',
}
