import React, { useState, useEffect } from 'react';
import { dropdownMenu, button, disabledStyle } from './SplitButton.styles';
import { MenuItemDef } from 'ag-grid-community';
import { noop, isEmpty, isNil } from 'lodash';
import { Tooltip, CircularProgress, Button, Menu, ButtonGroup } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
export type SplitButtonProps = {
  text?: string;
  icon?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  onButtonClick?: (() => void) | undefined;
  menuItems: (MenuItemDef | JSX.Element)[];
  buttonElement?: JSX.Element;
};

const SplitButton = ({
  text,
  icon,
  isDisabled,
  isLoading,
  onButtonClick,
  menuItems,
  buttonElement,
}: SplitButtonProps) => {
  const [isOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const toggle = () => setOpen(!isOpen);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };

  const buttonGroup = (
    <ButtonGroup classes={{ root: button }} size="small">
      {!buttonElement ? (
        <Button disabled={isDisabled} onClick={onButtonClick}>
          {isLoading ? (
            <CircularProgress size={20} color={'inherit'} disableShrink={true} />
          ) : (
            <i className={icon as string} />
          )}
          <span>{text}</span>
        </Button>
      ) : (
        buttonElement
      )}
      {isEmpty(menuItems) ? null : (
        <Button onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} disabled={isNil(menuItems)} size="small">
          <Menu
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: dropdownMenu }}
          >
            {menuItems.map((menuItem, index) => {
              if (React.isValidElement(menuItem)) {
                return menuItem;
              } else if (typeof menuItem === 'object' && 'name' in menuItem) {
                const { name, action = noop, icon, tooltip = '', disabled } = menuItem;
                const handleClick = () => {
                  toggle();
                  action();
                };
                return (
                  <Tooltip key={`${name}-${index}`} title={tooltip} placement="left">
                    <div onClick={!disabled ? handleClick : noop} className={disabled ? disabledStyle : ''}>
                      <i className={icon as string} />
                      <span>{name}</span>
                    </div>
                  </Tooltip>
                );
              }
              return null;
            })}
          </Menu>
        </Button>
      )}
    </ButtonGroup>
  );

  return buttonGroup;
};

export default SplitButton;
