import { classes } from 'typestyle';
import React from 'react';
import { isEmpty, isNil, isString } from 'lodash';
import BottomBar from './BottomBar';
import SubheaderDropdown from './SubheaderDropdown';
import { containerStyle, getFiltersStyle } from './Subheader.styles';
import ViewConfiguratorModal from 'src/components/ViewConfiguratorModal/ViewConfiguratorModal';
import { BackgroundDataLoading } from '../BackgroundDataLoading/BackgroundDataLoading';
import { Favorites } from './Favorites/Favorites';
import { SubheaderFilters } from './SubheaderFilters';
import { SortBySlice } from './Subheader.slice';
import SubheaderQuickActionButton from './SubheaderQuickActionButton';
import { SubheaderProps, SubheaderState } from 'src/components/Subheader/Subheader.types';
import { MassEdit } from 'src/components/MassEdit/MassEdit';
import { ConfigurablePostAction } from 'src/components/ConfigurableGrid/utils/ConfigurablePostActions';
import { ViewDataState } from 'src/types/Domain';
import { TenantConfigViewData } from 'src/dao/tenantConfigClient';
import SplitButton, { SplitButtonProps } from 'src/common-ui/components/SplitButton/SplitButton';
import { MenuItemDef } from 'ag-grid-community';
import { default as massEditStyle } from '../MassEdit/MassEdit.styles';
import { withStyles, createStyles, Tooltip, Badge, Button } from '@material-ui/core';
import { BasicItem } from 'src/worker/pivotWorker.types';
function showSortArrow(sortBy: SortBySlice): boolean {
  const hasSortOptions = sortBy.options.length > 0;
  const hasEmptySortOption = hasSortOptions && sortBy.options[0].text === '';
  const isEmptyOptionSelected = hasEmptySortOption && sortBy.selection === 0;
  const hasEmptyOptionOnly = hasEmptySortOption && sortBy.options.length === 1;
  const shouldHideSortArrow = !hasSortOptions || hasEmptyOptionOnly || isEmptyOptionSelected;
  return !shouldHideSortArrow;
}
const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      color: 'white',
    },
  })
)(Badge);

type MEButtonConfig = {
  selectedItem: BasicItem[];
  dataLoading: boolean;
  onClick(): void;
};

// TODO: This should probably be moved somewhere more sane
export function createMassEditButton(buttonConfig: MEButtonConfig) {
  return (
    <div>
      <Tooltip
        title={isEmpty(buttonConfig.selectedItem) ? 'Check items in the grid to mass edit' : ''}
        placement="bottom-end"
        enterDelay={0}
      >
        <div className={massEditStyle.actionButtonGroup} style={{ right: 115 }}>
          <StyledBadge
            badgeContent={buttonConfig.selectedItem.length}
            color="secondary"
            max={99}
            className="massedit-count"
            overlap="rectangular"
          >
            <div style={isEmpty(buttonConfig.selectedItem) ? { cursor: 'not-allowed' } : {}}>
              <Button
                color={'secondary'}
                className={massEditStyle.menuMassEditButton}
                disabled={buttonConfig.dataLoading || isEmpty(buttonConfig.selectedItem)}
                onClick={buttonConfig.onClick}
              >
                <i className={classes('fa fa-object-group', massEditStyle.menuMassEditIcon)} />
                <span>Mass Edit</span>
              </Button>
            </div>
          </StyledBadge>
        </div>
      </Tooltip>
    </div>
  );
}

export default class Subheader extends React.Component<SubheaderProps, SubheaderState> {
  constructor(props: SubheaderProps) {
    super(props);
    this.state = {
      loadingViewDefns: false,
      filtersVisible: false,
      filtersPopoverVisible: false,
    };
  }

  componentDidMount() {
    const { getViewDefns, sortByDefn, groupByDefn, pareDownDefn, countLimitDefn } = this.props;
    const defns: (string | null)[] = [];
    defns.push(groupByDefn || null);
    defns.push(sortByDefn || null);
    defns.push(countLimitDefn || null);
    defns.push(pareDownDefn || null);
    getViewDefns(defns);
  }

  toggleFilters = () => {
    this.setState({
      filtersVisible: !this.state.filtersVisible,
    });
  };

  toggleFiltersPopover = () => {
    this.setState({
      filtersPopoverVisible: !this.state.filtersPopoverVisible,
    });
  };
  handleUndoClick = () => {
    this.props.onOpenUndoPanel();
  };
  renderLegacyMassEdit = () => {
    const { extraLegacyActionButtons } = this.props;

    const hasLegacyActionButtons = !isNil(extraLegacyActionButtons);

    return (
      <div>
        {hasLegacyActionButtons && !isNil(extraLegacyActionButtons.massEdit) ? (
          <MassEdit {...extraLegacyActionButtons.massEdit} />
        ) : null}
      </div>
    );
  };
  onMergeActionButtons = () => {
    if (!isNil(this.props.extraActionButtons)) {
      const { workListMenuItem } = this.props.extraActionButtons;
      let menuItem: (MenuItemDef | JSX.Element)[] = [];

      if (!isNil(workListMenuItem) && workListMenuItem.length > 0) {
        let firstItem = workListMenuItem[0];
        let topMenuItem: SplitButtonProps;
        if (React.isValidElement(firstItem)) {
          topMenuItem = { buttonElement: firstItem as JSX.Element, menuItems: workListMenuItem.slice(1) };
        } else {
          const firstItemAsDef = firstItem as MenuItemDef;
          topMenuItem = {
            text: firstItemAsDef.name,
            icon: isString(firstItemAsDef.icon) ? firstItemAsDef.icon : undefined,
            isDisabled: firstItemAsDef.disabled,
            onButtonClick: firstItemAsDef.action,
            menuItems: workListMenuItem.slice(1),
          };
        }
        return <SplitButton {...topMenuItem} />;
      }
    } else if (!isNil(this.props.extraLegacyActionButtons)) {
      return this.renderLegacyMassEdit();
    }
    return <React.Fragment />;
  };

  render() {
    const {
      subheader,
      configureOptions,
      extraDropdowns,
      viewConfigurator,
      showDateRange,
      gridFilterModel,
      gridSortModel,
      resetGridModel,
      showUndoBtn,
    } = this.props;
    const { groupBy, sortBy, pareDown, countLimit, countLimitOptions } = subheader;

    const extraDropdownsElements =
      extraDropdowns && extraDropdowns.length > 0
        ? extraDropdowns.map((dropdown) => <SubheaderDropdown key={dropdown.label} {...dropdown} />)
        : [];

    // Filter inputs

    const flowStatusInputs = {
      showFlowStatus: this.props.showFlowStatus,
      flowStatus: subheader.flowStatus,
      flowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateFlowStatus: this.props.updateFlowStatus,
    };
    const altFlowStatusInputs = {
      showAltFlowStatus: this.props.showAlternateFlowStatus,
      altFlowStatus: subheader.altFlowStatus,
      altFlowStatusOptions: subheader.flowStatusConfig || this.props.flowStatusOptions,
      updateAltFlowStatus: this.props.updateAltFlowStatus,
    };
    const searchInputs = {
      showSearch: this.props.showSearch,
      search: subheader.search,
      searchReturn: this.props.searchReturn,
      updateSearchString: this.props.updateSearchString,
    };
    const altSearchInputs = {
      showAltSearch: this.props.showAlternateSearch,
      altSearch: subheader.altSearch,
      searchReturn: this.props.searchReturn,
      updateAltSearchString: this.props.updateAltSearchString,
    };
    const pareDownInputs = {
      showPareDown: !(
        !subheader.pareDownDefn ||
        pareDown.options.length < 1 ||
        (pareDown.options.length === 1 && pareDown.options[0].text === '')
      ),
      selections: pareDown.selections,
      options: pareDown.options,
      setPareDownSelections: this.props.setPareDownSelections,
    };
    const groupByInputs = {
      showGroupBy: !(
        groupBy.options.length < 1 ||
        (groupBy.options.length === 1 && groupBy.options[0].text === '') ||
        !subheader.groupByDefn
      ),
      groupBy,
      onChangeGroup: this.props.onChangeGroup,
      setGroupBySelection: this.props.setGroupBySelection,
    };
    const sortByInputs = {
      showSortBy: !(
        !subheader.sortByDefn ||
        sortBy.options.length < 1 ||
        (sortBy.options.length === 1 && sortBy.options[0].text === '')
      ),
      sortBy,
      onChangeSort: this.props.onChangeSort,
      setSortBySelection: this.props.setSortBySelection,
    };
    const sortArrowInputs = {
      showSortArrow: showSortArrow(sortBy),
      onChangeSort: this.props.onChangeSort,
      setSortByDirection: this.props.setSortByDirection,
    };
    const countLimitInputs = {
      showCountLimit: this.props.showCountLimit,
      countLimit,
      countLimitOptions,
      setCountLimit: this.props.setCountLimit,
    };
    const showTitle = !this.props.hideTitle && !isEmpty(this.props.title);

    return (
      <div className={containerStyle} data-qa="Subheader">
        <div className="top-bar">
          <div className={classes(showTitle ? 'title-container' : 'hidden-title-container')}>
            {showTitle && <h1 className="title">{this.props.title}</h1>}
            <BackgroundDataLoading viewDataState={this.props.viewDataState || ViewDataState.idle} />
          </div>
          <div className="quick-actions">
            {this.onMergeActionButtons()}
            {showUndoBtn && (
              <SubheaderQuickActionButton text={'Undo'} onClick={this.handleUndoClick} iconClass={'fa fa-undo'} />
            )}
            {configureOptions && configureOptions.type == 'enabled' && (
              <SubheaderQuickActionButton
                text={'Configure'}
                buttonDataQa={'configure-button'}
                iconClass={'far fa-cog'}
                onClick={configureOptions.onConfigureClick}
              />
            )}
            {viewConfigurator &&
              viewConfigurator.updateConfig &&
              (viewConfigurator.unmodifiedViewDefn.view || viewConfigurator.unmodifiedViewDefn.columns) && (
                <ViewConfiguratorModal subheaderTitle={this.props.title} {...viewConfigurator} />
              )}
            {viewConfigurator && (
              <Favorites
                viewConfigurator={viewConfigurator}
                favoritesList={subheader.favoritesList}
                favoritesSaveOverride={this.props.favoritesSaveOverride}
                getFavoritesList={this.props.getFavoritesList}
                setFavoritesList={this.props.setFavoritesList}
                subheaderValues={{
                  groupBy,
                  sortBy,
                  pareDown,
                  showCountLimit: this.props.showCountLimit,
                  countLimit,
                  countLimitOptions,
                  countLimitDefault: subheader.countLimitDefault,
                  groupByDefn: subheader.groupByDefn,
                  sortByDefn: subheader.sortByDefn,
                  pareDownDefn: subheader.pareDownDefn,
                }}
                subheaderHandlers={{
                  setGroupBySelection: this.props.setGroupBySelection,
                  setSortBySelection: this.props.setSortBySelection,
                  setSortByDirection: this.props.setSortByDirection,
                  setPareDownSelections: this.props.setPareDownSelections,
                  setCountLimit: this.props.setCountLimit,
                }}
                gridFilterModel={gridFilterModel}
                gridSortModel={gridSortModel}
                resetGridModel={resetGridModel}
              />
            )}
            {this.props.downloadLink && (
              <SubheaderQuickActionButton
                iconClass={'far fa-cloud-download'}
                iconDataQa="subheader-download-button"
                href={this.props.downloadLink}
              />
            )}
          </div>
          <section className={classes('filters', getFiltersStyle(true))}>
            <SubheaderFilters
              flowStatusInputs={flowStatusInputs}
              altFlowStatusInputs={altFlowStatusInputs}
              searchInputs={searchInputs}
              altSearchInputs={altSearchInputs}
              customElement={this.props.customEl}
              pareDownInputs={pareDownInputs}
              groupByInputs={groupByInputs}
              sortByInputs={sortByInputs}
              sortArrowInputs={sortArrowInputs}
              extraDropdownElements={extraDropdownsElements}
              countLimitInputs={countLimitInputs}
              showDateRange={showDateRange}
            />
          </section>
        </div>
        <BottomBar
          errorCondition={this.props.errorCondition}
          lookBackPeriod={subheader.lookBackPeriod}
          lookBackPeriods={this.props.lookBackPeriods}
          showLookBackPeriod={this.props.showLookBackPeriod}
          summary={this.props.summary}
          updateLookBackPeriod={this.props.updateLookBackPeriod}
        />
      </div>
    );
  }
}
