import React from 'react';
import { classes } from 'typestyle';

import { default as styles } from 'src/components/MassEdit/MassEdit.styles';
import { default as ReactSelect } from 'react-select';
import { debounce, isArray, isEmpty, isNil, isString, mapValues, omitBy } from 'lodash';
import DividedColumnDetailsDropdown from 'src/pages/AssortmentBuild/StyleEdit/DividedColumnDetailsSection/DividedColumnDetailsDropdown';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ViewApiConfig } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.types';
import { arrowlessNumberInputStyle } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerEditor';
import TemplateModalWithButton, { TemplateModalTitle } from '../TemplateModalWithButton/TemplateModalWithButton';
import {
  Button,
  CardActions,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { MuiThemeProvider, Badge } from '@material-ui/core';
import { muiTheme } from 'src/utils/Style/Theme';
import { BasicItem } from 'src/worker/pivotWorker.types';
import serviceContainer from 'src/ServiceContainer';
import { GridApi } from 'ag-grid-community';
import InputAdornment from '@material-ui/core/InputAdornment';
import AcceptButton from '../AcceptButton/AcceptButton';
import { MUIStyles } from '../LifecycleStoreModal/LifecycleStoreModal.styles';
import RejectButton from '../RejectButton/RejectButton';
import * as dialogStyles from '../TemplateModalWithButton/TemplateModalWithButton.styles';
export type MassEditSelectItemData = {
  value: string;
  label: string;
};

type MassEditConfigItem = {
  title?: string;
  dataIndex: string;
  editor?: string;
  renderer?: string;
  text?: string;
  dataApi?: ViewApiConfig;
  subtitle?: string;
};

type PrimaryConfigItem = {
  title: string;
  modifierTypes: MassEditConfigItem[];
};

export type MassEditConfig = {
  title: string;
  coordinateMap: {
    [s: string]: string;
  };
  modificationOptions: PrimaryConfigItem;
  productListing: MassEditConfigItem;
  hideEmpty?: boolean;
};

export type MassEditProps = {
  dataLoading?: boolean;
  getSelectedItems: () => BasicItem[];
  config?: MassEditConfig;
  title: string;
  handleCancel?: () => void;
  handleSubmit: (success: boolean) => void;
  gridApi: GridApi;
  isOpen: boolean;
  onModalToggle: (isOpen: boolean) => void;
  onSelectedItems: (selectedItems: BasicItem[]) => void;
};

export type MassEditState = {
  selectedModifier: string | null; // the dataIndex to mass edit
  selectedEditor: string | null; // the dataIndex editor type
  selectedParser: string | null;
  selectedEditorApi: ViewApiConfig | null;
  selectedItems: BasicItem[]; // items to update the dataIndex on (stylecolor ids)
  selectedSubtitle: string | null;
  modifierValue: string | string[] | number | null;
  userSetNull: boolean;
};

export type MassEditSubmissionData = {
  selectedModifier: string | null; // the dataIndex to mass edit
  selectedItems: MassEditSelectItemData[]; // items to update the dataIndex on (stylecolor ids)
  modifierValue: string | string[] | number | null;
};

type ReactSelectEvent = any;

const initialState = {
  selectedEditor: null,
  selectedModifier: null,
  selectedEditorApi: null,
  selectedItems: [],
  modifierValue: null,
  userSetNull: false,
  selectedParser: null,
  selectedSubtitle: null,
};
export class MassEdit extends React.Component<MassEditProps, MassEditState> {
  state: MassEditState;

  constructor(props: MassEditProps) {
    super(props);
    this.state = initialState;
    this.updateSelectedItems = this.updateSelectedItems.bind(this);
  }

  selectionsValid() {
    const { selectedEditor, modifierValue } = this.state;
    const hideEmpty = this.props.config ? this.props.config.hideEmpty : false;
    const isValid = !isEmpty(this.state.selectedItems);

    if (selectedEditor === 'numberInput') {
      // numberInputs can set values to null via checkbox
      return isValid;
    }

    return isValid && (!isNil(modifierValue) || !hideEmpty);
  }

  componentDidUpdate(prevProps: MassEditProps) {
    // listen to the outer ag-grid selection event, and update the internal
    // state based on it
    if (this.props.gridApi !== prevProps.gridApi && this.props.gridApi != null) {
      prevProps.gridApi?.removeEventListener('selectionChanged', this.updateSelectedItems);
      this.props.gridApi.addEventListener('selectionChanged', this.updateSelectedItems);
      this.updateSelectedItems();
    }
  }
  componentWillUnmount() {
    if (this.props.gridApi != null) {
      this.props.gridApi.removeEventListener('selectionChanged', this.updateSelectedItems);
    }
  }
  componentDidMount() {
    if (this.props.gridApi) {
      this.props.gridApi.addEventListener('selectionChanged', this.updateSelectedItems);
      this.updateSelectedItems();
    }
  }

  renderRangeAndModifierSelectors(modifierConfig: PrimaryConfigItem) {
    const { title, modifierTypes } = modifierConfig;
    const options = modifierTypes.map((modifier) => {
      const { dataIndex, text, editor, renderer, subtitle, dataApi } = modifier;
      return {
        value: dataIndex,
        label: text,
        editor: editor,
        parser: renderer,
        subtitle: subtitle,
        selectedEditorApi: dataApi,
      };
    });

    const eventDropdown = (
      <div className={styles.sectionContentItem}>
        <div className={styles.subSectionTitle} data-qa={'mass-edit-event-dropdown'}>
          Select Event Type:
        </div>
        <ReactSelect options={options} className={'mass-edit-event-dropdown'} onChange={this.handleModifierSelection} />
      </div>
    );
    const subtitle = this.state.selectedSubtitle || 'Specify Value:';

    return (
      <section>
        <div className={styles.sectionTitle}>{title}</div>
        <div className={styles.sectionContent}>
          {modifierTypes.length > 1 ? eventDropdown : undefined}
          <div className={classes(styles.sectionContentItem, styles.modifierInput)}>
            {!isNil(this.state.selectedEditor) && <div className={styles.subSectionTitle}>{subtitle}</div>}
            {this.getModifier()}
          </div>
        </div>
      </section>
    );
  }

  getModifier() {
    const { selectedEditor, selectedEditorApi } = this.state;
    const hideEmpty = this.props.config ? this.props.config.hideEmpty : false;
    switch (selectedEditor) {
      case 'validValuesEditor':
        const dataApi = selectedEditorApi;
        if (dataApi == null) {
          return <div>No Api for Dropdown</div>; // selecting dropdown doesn't have an api. Error case.
        }
        return (
          <DividedColumnDetailsDropdown
            key={this.state.selectedModifier}
            dataQa={'mass-edit-select-modifier'}
            value={''}
            styleId={''}
            asCsv={false}
            multiSelect={false}
            dataApi={dataApi}
            handleDropdownChange={this.handleModifierDropdownChange}
          />
        );
      case 'validValuesMulti':
        if (selectedEditorApi == null) {
          return <div>No Api for Dropdown</div>; // selecting dropdown doesn't have an api. Error case.
        }
        return (
          <DividedColumnDetailsDropdown
            key={this.state.selectedModifier}
            dataQa={'mass-edit-select-modifier'}
            value={''}
            styleId={''}
            asCsv={false}
            multiSelect={true}
            dataApi={selectedEditorApi}
            handleDropdownChange={this.handleModifierDropdownChange}
          />
        );
      case 'numberInput': {
        const { userSetNull } = this.state;
        const empty = !hideEmpty && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={userSetNull} onChange={this.toggleUserSetNull} />}
              label="Set value to 'empty'"
            />
          </div>
        );
        return (
          <React.Fragment>
            <input
              type="number"
              disabled={userSetNull}
              onChange={this.handleModifierNumberChange}
              data-qa={'mass-edit-input-number'}
              className={arrowlessNumberInputStyle}
            />
            {empty}
          </React.Fragment>
        );
      }
      case 'percent': {
        const { userSetNull } = this.state;
        const empty = !hideEmpty && (
          <div>
            <FormControlLabel
              control={<Checkbox checked={userSetNull} onChange={this.toggleUserSetNull} />}
              label="Set value to 'empty'"
            />
          </div>
        );
        return (
          <React.Fragment>
            <Input
              onChange={this.handleModifierNumberChange}
              data-qa={'mass-edit-percent'}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
            />
            {empty}
          </React.Fragment>
        );
      }
      case 'textInput': {
        return (
          <React.Fragment>
            <input
              type="text"
              onChange={this.handleModifierStringChange}
              data-qa={'mass-edit-input-text'}
              className={arrowlessNumberInputStyle}
            />
          </React.Fragment>
        );
      }
      default:
        return null;
    }
  }

  renderSubmitButton() {
    let button;
    const enableButton =
      this.state.selectedSubtitle && (this.state.modifierValue || this.state.userSetNull) ? false : true;

    if (!this.selectionsValid() || this.state.selectedItems.length > 700) {
      const tip =
        this.state.selectedItems.length > 700
          ? 'Please select 700 items or less.'
          : 'All section data is required to submit.';
      button = (
        <Tooltip title={tip}>
          <div>
            <button className={styles.actionButton} onClick={this.handleSubmit} disabled={true}>
              <i className="far fa-check" />
              SUBMIT
            </button>
          </div>
        </Tooltip>
      );
    } else {
      button = (
        <button className={styles.actionButton} onClick={this.handleSubmit} disabled={enableButton}>
          <i className="far fa-check" />
          SUBMIT
        </button>
      );
    }

    return button;
  }

  handleModifierSelection = (event: ReactSelectEvent) => {
    this.setState({
      selectedModifier: event.value,
      selectedEditor: event.editor,
      selectedParser: event.parser,
      selectedSubtitle: event.subtitle,
      selectedEditorApi: event.selectedEditorApi,
    });
  };

  handleModifierNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedParser } = this.state;

    let value = +event.target.value; // convert string to number
    if (selectedParser === 'percent') {
      value = value / 100;
    }

    this.setState({
      modifierValue: value,
      userSetNull: false,
    });
  };

  handleModifierStringChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modifierValue = event.target.value;
    this.setState({
      modifierValue,
      userSetNull: false,
    });
  };

  toggleUserSetNull = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    this.setState({
      modifierValue: null,
      userSetNull: checked,
    });
  };

  handleModifierDropdownChange = (selection: MassEditSelectItemData | MassEditSelectItemData[]) => {
    if (isArray(selection)) {
      const selectionValue = selection.map((select) => select.value);
      this.setState({
        modifierValue: selectionValue,
      });
    } else {
      this.setState({
        modifierValue: selection.value,
      });
    }
  };
  // we debounce this because it gets called for every selection change, and if you click the header checkbox,
  // it gets called for every row, which is expensive (see INT-3199)
  updateSelectedItems = debounce(() => {
    this.setState({
      selectedItems: this.props.getSelectedItems(),
    });
    this.props.onSelectedItems(this.props.getSelectedItems());
  }, 16);

  handleSubmit = () => {
    if (!this.selectionsValid() || this.state.selectedModifier == null || this.props.config == null) {
      return;
    }
    const modifier = this.state.selectedModifier;
    const selectedEditor = this.state.selectedEditor;
    let value = this.state.modifierValue;
    if (!isNil(value) && selectedEditor === 'percent' && !isArray(value)) {
      if (isString(value)) {
        value = parseFloat(value) / 100;
      } else {
        value = value / 100;
      }
    }
    const coordMap = this.props.config.coordinateMap;
    const payload = {
      coordinates: this.state.selectedItems.map((item) => {
        return omitBy(
          mapValues(coordMap, (v) => item[v]),
          isNil
        );
      }),
      [modifier]: value,
    };
    const submissionPromise = serviceContainer.pivotService.coarseEditSubmitData(payload);
    this.setState(initialState);
    this.props.onModalToggle(false);
    submissionPromise.then(() => this.props.handleSubmit(true)).catch(() => this.props.handleSubmit(false));
  };

  render() {
    const { config } = this.props;
    const itemSelections = this.state.selectedItems;
    let mainContent;
    if (isNil(config) || isNil(itemSelections)) {
      mainContent = <div />;
    } else {
      const { modificationOptions } = config;
      const { modifierTypes } = modificationOptions;
      if (modifierTypes.length == 1 && !this.state.selectedModifier) {
        this.setState({
          selectedModifier: modifierTypes[0].dataIndex,
          selectedEditor: modifierTypes[0].editor!,
          selectedParser: modifierTypes[0].renderer!,
          selectedSubtitle: modifierTypes[0].subtitle!,
        });
      }
      mainContent = (
        <div className={styles.contentContainerNoProductList}>
          <div className={styles.verticalContentItemsContainer}>
            <article className={styles.contentItemNoProductList}>
              {this.renderRangeAndModifierSelectors(modificationOptions)}
              <section className={'count-bottom'}>{itemSelections.length} item(s) will be modifed</section>
            </article>
          </div>
        </div>
      );
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Dialog open={this.props.isOpen} maxWidth={false} PaperProps={{ style: { overflow: 'visible' } }}>
          <DialogTitle classes={{ root: dialogStyles.modalTitle }} disableTypography={true}>
            <TemplateModalTitle
              title={this.props.title}
              onCancel={() => {
                this.props.onModalToggle(false);
                this.setState(initialState);
              }}
            />
          </DialogTitle>
          <DialogContent classes={{ root: dialogStyles.modalContent }}>{mainContent}</DialogContent>
          <CardActions classes={MUIStyles.cardActions}>
            <div>{this.renderSubmitButton()}</div>
          </CardActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}
