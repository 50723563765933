import * as React from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { isNumber } from 'util';
import Tooltip from '@material-ui/core/Tooltip';
import { AutoSizer, Grid, GridCellProps, ScrollParams } from 'react-virtualized';
import * as _ from 'lodash';
import { highlight, subtleHighlight, sliderOverlayHighlight } from '../../theme';
import {
  SeriesColumnDataOptions,
  SeriesColumnOptions,
  SeriesOptionsType,
  SeriesSplineOptions,
  TooltipFormatterContextObject,
} from 'highcharts';
import { isEqual, isNil } from 'lodash';

export type NumStrNull = number | string | null;
export interface DataItem {
  [k: string]: NumStrNull;
}

export interface SeriesInfo {
  displayName: string;
  color?: string;
  propertyName: string;
  renderer?: (n: number) => NumStrNull;
}

export interface PerformanceChartProps {
  topTitle?: string;
  className?: string;
  bottomTitle?: string;
  idProperty: string;
  displayNameProperty: string;
  summaryProperty: string;
  cumulativeProperty?: string;
  onItemSelect?: (item: SeriesColumnDataOptions, i: number) => void;
  data: SeriesColumnDataOptions[];
  itemWidth?: number;
  topSeries: SeriesInfo[];
  bottomSeries: SeriesInfo[];
  topHiddenSeries?: string[];
  bottomHiddenSeries?: string[];
  defaultIndex?: number;
  restrictHeight?: boolean;
  children: (item: SeriesColumnDataOptions) => Element | JSX.Element | React.ReactChild;
  onToggleSeries?: (topHiddenSeries: string[], bottomHiddenSeries: string[]) => void;
}

export interface PerformanceChartState {
  topConfig: Highcharts.Options;
  bottomConfig: Highcharts.Options;
  miniConfig: Highcharts.Options | {};
  scrollLeft: number;
  highlightedIndex?: number;
  highlightedId?: string;
  itemWidth?: number;
}

interface WithSetData extends Highcharts.Series {
  // this is a private, undocumented interface on the series
  searchPoint: (n: {}, v: boolean) => { index: number };
}

interface WithSeries extends Highcharts.Chart {
  series: WithSetData[];
}
type HighchartsReactRef = { chart: WithSeries; container: React.RefObject<HTMLDivElement> };

const MARGIN = 50;

const minibarHeight = 30;
const imgBarHeight = 70;
export default class PerformanceChart extends React.Component<PerformanceChartProps, PerformanceChartState> {
  mini = React.createRef<HighchartsReactRef>();
  c1 = React.createRef<HighchartsReactRef>();
  c2 = React.createRef<HighchartsReactRef>();
  grid?: Grid;
  isSummaryDragging!: boolean;

  static getDerivedStateFromProps(props: PerformanceChartProps, state: PerformanceChartState) {
    const newState = { ...state };
    let changes = false;

    if (newState.itemWidth !== props.itemWidth) {
      newState.itemWidth = props.itemWidth;
      if (state.highlightedIndex && props.data[state.highlightedIndex]) {
        const dataItem = props.data[state.highlightedIndex];
        if (dataItem[props.idProperty] !== state.highlightedId) {
          newState.highlightedIndex = props.data.findIndex((v) => v[props.idProperty] === state.highlightedId);
        } else {
          delete newState.highlightedId;
          delete newState.highlightedIndex;
        }
      }
      changes = true;
    }

    return changes ? newState : null;
  }

  static toSerieConfig(
    items: SeriesColumnDataOptions[],
    serie: SeriesInfo,
    s: number,
    hiddenSeries?: string[]
  ): SeriesColumnOptions | SeriesSplineOptions {
    const isHidden = hiddenSeries && _.includes(hiddenSeries, serie.displayName);
    if (s === 0 || s === 1) {
      return {
        type: 'column',
        name: serie.displayName,
        color: serie.color,
        yAxis: 0,
        data: items.map((i) => i[serie.propertyName]!),
        animation: false,
        visible: !isHidden,
      };
    }
    return {
      name: serie.displayName,
      type: 'spline',
      color: serie.color,
      marker: {
        radius: 3,
        symbol: 'circle',
        lineColor: serie.color,
        fillColor: serie.color,
      },
      yAxis: 1,
      data: items.map((i) => i[serie.propertyName]),
      animation: false,
      visible: !isHidden,
    };
  }

  static getCumulativeData(items: SeriesColumnDataOptions[], dataIndex: string): (number | null)[] {
    const sum: number = items.reduce((acc: number, item: SeriesColumnDataOptions) => {
      const v = item[dataIndex];
      return isNumber(v) ? v + acc : acc;
    }, 0);

    const results: (number | null)[] = [];

    let accumulation = 0;
    for (let i = 0; i < items.length; ++i) {
      const v = items[i][dataIndex];
      if (isNumber(v)) {
        accumulation = Math.min(1, v / sum + accumulation);
      }
      results.push(accumulation);
    }
    return results;
  }

  createState(): PerformanceChartState {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const performanceChartSelf = this;
    const { props } = performanceChartSelf;
    const items = props.data;

    const topSeries = props.topSeries.map((serie, s) => {
      return PerformanceChart.toSerieConfig(items, serie, s, props.topHiddenSeries);
    });

    let bottomSeries = props.bottomSeries.map((serie, s) => {
      return PerformanceChart.toSerieConfig(items, serie, s, props.bottomHiddenSeries);
    });
    bottomSeries = _.filter(bottomSeries, (series) => {
      return series.name !== '';
    });

    const lastBottomChartRenderer = _.last(props.bottomSeries)!.renderer;

    if (props.cumulativeProperty) {
      topSeries.push({
        name: 'Cumulative %',
        type: 'spline',
        color: 'black',
        // lineWidth: 0.1, I dont think this exists
        marker: {
          radius: 1,
          symbol: 'circle',
          fillColor: 'black',
          lineColor: undefined,
        },
        yAxis: 1,
        data: PerformanceChart.getCumulativeData(items, props.cumulativeProperty),
      });
    }
    function identity<T>(arg: T): T {
      return arg;
    }

    const seriesData = items.map((i) => i[props.summaryProperty]) as (
      | number
      | [number, number]
      | [string, number]
      | Highcharts.Point
    )[];
    const state: PerformanceChartState = {
      scrollLeft: 0,
      itemWidth: props.itemWidth,
      miniConfig: {
        credits: { enabled: false },
        chart: {
          type: 'area',
          height: minibarHeight,
          margin: 0,
        },
        title: {
          text: null,
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          title: null,
          labels: {
            enabled: false,
          },
          lineWidth: 0,
          tickLength: 0,
          plotBands: [
            {
              color: highlight,
            },
          ],
        },
        yAxis: {
          title: null,
          labels: {
            enabled: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        series: [{ data: seriesData }],
        enableMouseTracking: false,
      },
      topConfig: {
        credits: { enabled: false },
        tooltip: {
          formatter: function(this: TooltipFormatterContextObject) {
            const series = this.series;
            const seriesName = series.name;
            const value = this.point.y;
            // TODO check this
            // I believe this is a private property
            // @ts-ignore
            const index = series.index;
            const seriesInfo = performanceChartSelf.props.topSeries[index];
            const category = this.point.category;
            if (isNil(value)) {
              return `${category} <br /> ${seriesName}`;
            }
            if (seriesInfo) {
              const renderFn = seriesInfo.renderer || identity;
              return `${category} <br /> ${seriesName} : <b>${renderFn(value)}</b>`;
            }
            return `${category} <br /> ${seriesName} : <b>${Math.round(100 * value).toLocaleString()}%</b>`;
          },
        },
        chart: {
          marginLeft: MARGIN,
          marginRight: MARGIN,
          animation: false,
        },
        title: {
          text: props.topTitle,
        },
        xAxis: [
          {
            gridLineWidth: 0,
            startOnTick: false,
            endOnTick: false,
            categories: items.map((i) => i[props.displayNameProperty] as string),
            labels: {
              enabled: false,
            },
            plotBands: [
              {
                color: highlight,
              },
            ],
          },
        ],
        yAxis: [
          {
            gridLineWidth: 0,
            startOnTick: false,
            endOnTick: false,
            labels: {
              style: {
                color: '#89A54E',
              },
            },
            title: {
              text: '',
              style: {
                color: '#89A54E',
              },
            },
          },
          {
            startOnTick: false,
            endOnTick: false,
            tickInterval: 0.2,
            title: {
              text: '',
              style: {
                color: 'red',
              },
            },
            labels: {
              formatter: function(this: { value: number }) {
                return this.value * 100 + '%';
              },
              style: {
                color: '#4572A7',
              },
            },
            opposite: true,
          },
        ],
        series: topSeries,
      },
      bottomConfig: {
        credits: { enabled: false },
        tooltip: {
          formatter: function(this: TooltipFormatterContextObject) {
            const series = this.series;
            const seriesName = series.name;
            const value = this.point.y;
            // TODO check this
            // I believe this is a private interface
            // @ts-ignore
            let index = series.index;
            const category = this.point.category;
            if (seriesName !== performanceChartSelf.props.bottomSeries[index].displayName) {
              index = _.findIndex(performanceChartSelf.props.bottomSeries, { displayName: seriesName });
            }
            if (isNil(value)) {
              return `${category} <br /> ${seriesName}`;
            }
            const seriesInfo = performanceChartSelf.props.bottomSeries[index];
            if (seriesInfo) {
              const renderFn = seriesInfo.renderer || identity;
              return `${category} <br /> ${seriesName} : <b>${renderFn(value)}</b>`;
            }
            return `${category} <br /> ${seriesName} : <b>${Math.round(100 * value).toLocaleString()}%</b>`;
          },
        },
        chart: {
          alignTicks: false,
          height: 200,
          marginLeft: MARGIN,
          marginRight: MARGIN,
          animation: false,
        },
        title: {
          text: props.bottomTitle,
        },
        xAxis: [
          {
            gridLineWidth: 0,
            startOnTick: false,
            endOnTick: false,
            categories: items.map((i) => i[props.displayNameProperty] as string),
            labels: {
              enabled: true,
            },
            plotBands: [
              {
                color: highlight,
              },
            ],
          },
        ],
        yAxis: [
          {
            gridLineWidth: 0,
            startOnTick: false,
            endOnTick: false,
            labels: {
              enabled: true,
              style: {
                color: '#89A54E',
              },
            },
            title: {
              text: '',
              style: {
                color: '#89A54E',
              },
            },
          },
          {
            title: {
              text: '',
              style: {},
            },
            labels: {
              formatter: function(this: { value: number }) {
                return lastBottomChartRenderer ? (lastBottomChartRenderer(this.value) as string) : '$' + this.value;
              },
              style: {
                color: '#4572A7',
              },
            },
            opposite: true,
          },
        ],
        series: bottomSeries,
      },
    };
    if (props.defaultIndex != null) {
      state.highlightedIndex = props.defaultIndex;
    }
    return state;
  }

  constructor(props: PerformanceChartProps) {
    super(props);
    this.state = this.createState();
    this.state = this.mergeEvents(this.state);
    if (this.state.highlightedIndex != null) {
      this.highlightAtIndex(this.state.highlightedIndex, 'item-highlight');
    }
  }

  mergeEvents = (state: PerformanceChartState) => {
    const eventConfig = {
      chart: {
        events: {
          click: this.onClick,
        },
      },
      plotOptions: {
        series: {
          events: {
            click: this.onClick,
            legendItemClick: this.onToggleSeries,
          },
        },
      },
    };
    state.topConfig = _.merge(state.topConfig, eventConfig);
    state.bottomConfig = _.merge(state.bottomConfig, eventConfig);
    state.miniConfig = _.merge(state.miniConfig, {
      chart: {
        events: {
          click: this.onSummaryClick,
        },
      },
      plotOptions: {
        series: {
          events: {
            click: this.onSummaryClick,
          },
        },
      },
    });
    return state;
  };

  updateChartData() {
    const props = this.props;
    const items = props.data;

    function updateChart(c: HighchartsReactRef, series: SeriesOptionsType[]) {
      if (c) {
        const chart = c.chart;
        chart.xAxis[0].setCategories(items.map((i) => i[props.displayNameProperty]));
        chart.series.map((i) => i).forEach((serie) => serie.remove(true));

        series.forEach((serie) => chart.addSeries(serie));
      }
    }

    const c1 = this.c1.current;
    const c2 = this.c2.current;
    const mini = this.mini.current;

    const topSeries: (SeriesColumnOptions | SeriesSplineOptions)[] = props.topSeries.map((serie, s) => {
      return PerformanceChart.toSerieConfig(items, serie, s, props.topHiddenSeries);
    });

    let bottomSeries: (SeriesColumnOptions | SeriesSplineOptions)[] = props.bottomSeries.map((serie, s) => {
      return PerformanceChart.toSerieConfig(items, serie, s, props.bottomHiddenSeries);
    });
    bottomSeries = _.filter(bottomSeries, (series) => {
      return series.name !== '';
    });
    if (props.cumulativeProperty) {
      topSeries.push({
        name: 'Cumulative %',
        type: 'spline',
        color: 'black',
        lineWidth: 0.1,
        marker: {
          radius: 1,
          symbol: 'circle',
          fillColor: 'black',
        },
        yAxis: 1,
        data: PerformanceChart.getCumulativeData(items, props.cumulativeProperty),
      });
    }
    // @ts-ignore
    const topSeriesData = topSeries[2].data!.concat(topSeries[3].data).map((x: number) => x);
    const maxValue = Math.ceil(Math.max(...topSeriesData));
    const minValue = Math.floor(Math.min(...topSeriesData) * 10) / 10;
    if (c1 && c2) {
      c1.chart.yAxis[1].setExtremes(minValue, maxValue);
      updateChart(c1, topSeries);
      updateChart(c2, bottomSeries);
    }
    if (mini) {
      const chart: WithSeries = mini.chart;
      if (chart) {
        const newData = items.map((j) => j[props.summaryProperty]);
        chart.series[0].setData(newData, true);
      }
    }

    this.updateExtremes();
  }

  cellRenderer = (gcp: GridCellProps) => {
    const { data, displayNameProperty } = this.props;
    const item = data[gcp.columnIndex];
    const displayName = item[displayNameProperty];
    const itemDescription = displayName === null ? ' ' : displayName;

    return (
      <Tooltip title={itemDescription} placement="bottom">
        <div
          key={gcp.columnIndex}
          data-id={gcp.columnIndex}
          onClick={this.onGroupClick}
          style={{
            ...gcp.style,
            border:
              this.state.highlightedIndex === gcp.columnIndex ? '1px solid ' + highlight : '1px solid transparent',
            cursor: 'pointer',
          }}
        >
          {this.props.children(item)}
        </div>
      </Tooltip>
    );
  };

  onSummaryMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    this.isSummaryDragging = true;
    this.onSummaryClick(e.nativeEvent);
  };

  onSummaryMouseUp = () => {
    this.isSummaryDragging = false;
  };

  onSummaryMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.isSummaryDragging) {
      this.onSummaryClick(e.nativeEvent);
    }
  };

  onSummaryClick = (e: React.MouseEvent<HTMLDivElement>['nativeEvent']) => {
    const mini = this.mini.current;
    const grid = this.grid;
    if (mini && grid && this.state.itemWidth) {
      const chart = mini.chart;
      if (chart && e instanceof PointerEvent) {
        const event = chart.pointer.normalize(e);
        const point = chart.series[0].searchPoint(event, true);
        if (point) {
          const scrollLeft = point.index * this.state.itemWidth - grid.props.width / 2;
          this.setScrollLeft(Math.max(0, scrollLeft));
        }
      }
    }
  };

  onGridScroll = (params: ScrollParams) => {
    if (params.scrollLeft > this.state.scrollLeft + 10 || params.scrollLeft < this.state.scrollLeft - 10) {
      this.setState(
        {
          scrollLeft: params.scrollLeft,
        },
        () => {
          this.updateExtremes();
        }
      );
    }
  };

  updateExtremes = () => {
    const c1 = this.c1.current;
    const c2 = this.c2.current;
    const mini = this.mini.current;
    const grid: Grid = this.grid as Grid;
    const ln = this.props.data.length;

    if (c1 && c2 && grid && mini) {
      // for some crazy reason grid width can be negative
      const widthAvailable = !!this.state.itemWidth || grid.props.width > 0;
      if (!widthAvailable) {
        return;
      }
      const scrollWidth = ln * (this.state.itemWidth || grid.props.width / ln);
      const chart1 = c1.chart;
      const chart2 = c2.chart;
      const miniChart = mini.chart;
      const scrollLeft = grid.state.scrollLeft || 0;
      if (chart1 && chart2 && miniChart) {
        const ratioOffStart = scrollLeft / scrollWidth;
        const ratioOffEnd = (scrollLeft + chart1.chartWidth - MARGIN * 2) / scrollWidth;
        let startPos = ratioOffStart * ln;
        let endPos = Math.min(ratioOffEnd * ln - 1, ln - 1);
        if (_.isNaN(startPos)) {
          startPos = 0;
        }
        if (_.isNaN(endPos)) {
          endPos = startPos;
        }
        chart1.xAxis[0].setExtremes(startPos, endPos, true);
        chart2.xAxis[0].setExtremes(startPos, endPos, true);
        const miniAxis = miniChart.xAxis[0];
        miniAxis.removePlotBand('view-area');
        const pb = {
          id: 'view-area',
          from: startPos,
          to: endPos,
          color: sliderOverlayHighlight,
          zIndex: 4,
        };
        miniAxis.addPlotBand(pb);
        if (scrollLeft > scrollWidth) {
          this.setScrollLeft(0);
        }
      }
    }
  };

  onChartScroll = (e: React.WheelEvent<HTMLElement>) => {
    const grid = this.grid as Grid;
    if (e.deltaX !== 0 && grid) {
      this.setScrollLeft(grid.state.scrollLeft + e.deltaX);
      e.preventDefault();
    }
  };

  setScrollLeft = (scrollLeft: number) => {
    const c1 = this.c1.current;
    const c2 = this.c2.current;
    const grid = this.grid as Grid;

    if (c1 && c2) {
      const chart1 = c1.chart;
      const chart2 = c2.chart;
      if (chart1 && chart2) {
        const totalWidth = (grid.props.columnWidth as number) * this.props.data.length;
        const shiftWidthByViewport = totalWidth - (chart1.chartWidth - MARGIN * 2);
        grid.scrollToPosition({
          scrollLeft: Math.min(scrollLeft, shiftWidthByViewport),
          scrollTop: grid.state.scrollTop,
        });
      }
    }
  };

  componentDidMount() {
    this.updateChartData();
    const n = this.state.highlightedIndex;
    if (!isNil(n)) {
      this.highlightAtIndex(n, 'item-highlight');
    } else if (this.props.defaultIndex) {
      this.setState({
        highlightedIndex: this.props.defaultIndex,
      });
    }
  }

  componentDidUpdate(prevProps: PerformanceChartProps, prevState: PerformanceChartState) {
    this.updateChartData();
    const n = this.state.highlightedIndex;
    if (!isNil(n)) {
      this.highlightAtIndex(n, 'item-highlight');
    } else if (this.props.defaultIndex) {
      this.setState({
        highlightedIndex: this.props.defaultIndex,
      });
    }
    if (!isEqual(prevProps.data, this.props.data)) {
      if (!isNil(n) && this.props.onItemSelect) {
        this.props.onItemSelect(this.props.data[n], n);
      }
    }
  }

  onGroupClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget instanceof HTMLDivElement) {
      if (e.currentTarget.dataset && e.currentTarget.dataset.id) {
        const columnIndex = e.currentTarget.dataset.id;
        if (columnIndex) {
          this.highlightAtIndex(parseInt(columnIndex, 10), 'item-highlight');
          return;
        }
      }
    }
  };

  onClick = (e: Highcharts.PointerEventObject) => {
    const c1 = this.c1.current;
    const c2 = this.c2.current;

    if (c1 && c2 && e instanceof MouseEvent) {
      const chart1 = c1.chart;
      const point1 = chart1.series.map((c) => c.searchPoint(e, true)).find((p) => p != null);

      if (point1) {
        this.highlightAtIndex(point1.index, 'item-highlight');
        return;
      }
      const chart2 = c2.chart;
      const event2 = chart2.pointer.normalize(e);
      const point2 = chart2.series.map((serie) => serie.searchPoint(event2, true)).find((p) => p != null);

      if (point2) {
        this.highlightAtIndex(point2.index, 'item-highlight');
        return;
      }
    }
  };

  onToggleSeries = (e: any) => {
    const selectedChart = e.target.chart;
    const selectedSeriesName = e.target.name;

    const c1 = this.c1.current;
    const c2 = this.c2.current;

    if (c1 && c2) {
      const chart1 = c1.chart;
      const chart2 = c2.chart;
      if (chart1 && chart2) {
        const topHiddenSeries = chart1.series.filter((c) => !c.visible).map((c) => c.name || '') || [];
        const bottomHiddenSeries = chart2.series.filter((c) => !c.visible).map((c) => c.name || '') || [];

        if (selectedChart === chart1) {
          const selectedSeries = chart1.series.filter((s) => s.name === selectedSeriesName)[0];

          if (_.includes(topHiddenSeries, selectedSeriesName)) {
            selectedSeries.show();
            _.remove(topHiddenSeries, (el) => el === selectedSeriesName);
          } else {
            selectedSeries.hide();
            topHiddenSeries.push(selectedSeriesName);
          }
        }
        if (selectedChart === chart2) {
          const selectedSeries = chart2.series.filter((s) => s.name === selectedSeriesName)[0];

          if (_.includes(bottomHiddenSeries, selectedSeriesName)) {
            selectedSeries.show();
            _.remove(bottomHiddenSeries, (el) => el === selectedSeriesName);
          } else {
            selectedSeries.hide();
            bottomHiddenSeries.push(selectedSeriesName);
          }
        }
        if (this.props.onToggleSeries) {
          this.props.onToggleSeries(topHiddenSeries, bottomHiddenSeries);
        }
      }
    }
    return false;
  };

  highlightAtIndex(index: number, id: string) {
    if (index >= this.props.data.length) {
      index = 0;
    }
    const c1 = this.c1.current;
    const c2 = this.c2.current;
    const mini = this.mini.current;
    let dataItem = this.props.data[index];
    let highlightedId;
    if (dataItem) {
      highlightedId = dataItem[this.props.idProperty] as string;
    }

    if (!isEqual(this.state.highlightedIndex, index) || !isEqual(this.state.highlightedId, highlightedId)) {
      this.setState({
        highlightedIndex: index,
        highlightedId: highlightedId,
      });
      if (this.props.onItemSelect) {
        this.props.onItemSelect(dataItem, index);
      }
    }
    if (c1 && c2 && mini) {
      let chart1, chart2, miniChart;
      chart1 = c1.chart;
      chart2 = c2.chart;
      miniChart = mini.chart;
      if (chart1 && chart2 && miniChart) {
        const xAxis1 = chart1.xAxis[0];
        const xAxis2 = chart2.xAxis[0];
        const miniAxis = miniChart.xAxis[0];
        dataItem = this.props.data[index];
        xAxis1.removePlotBand(id);
        xAxis2.removePlotBand(id);
        miniAxis.removePlotBand(id);
        const pb = {
          id: id,
          from: index - 0.5,
          to: index + 0.5,
          color: subtleHighlight,
        };
        xAxis1.addPlotBand(pb);
        xAxis2.addPlotBand(pb);
        const pbMini = {
          id: id,
          from: index - 0.5,
          to: index + 0.5,
          color: subtleHighlight,
          zIndex: 5,
        };
        miniAxis.addPlotBand(pbMini);
      }
    }
  }

  setChartHeights = ({ height, width }: { height: number; width: number }) => {
    const topPercentage = 0.55;
    const bottomPercentage = 0.45;

    this.setState({
      topConfig: _.merge(
        {}, // need an empty to trigger the 'pure' config
        this.state.topConfig,
        { chart: { height: height * topPercentage, width: width } }
      ),
      bottomConfig: _.merge({}, this.state.bottomConfig, {
        chart: { height: height * bottomPercentage, width: width },
      }),
    });
  };

  render() {
    const { topConfig, bottomConfig, miniConfig } = this.state;
    let middleChartsEl: JSX.Element;
    if (this.props.restrictHeight) {
      const limitedHeight = { height: `calc(100% - ${minibarHeight + imgBarHeight}px)` };
      middleChartsEl = (
        <div style={{ display: 'block', overflow: 'hidden', ...limitedHeight }} onWheel={this.onChartScroll}>
          <AutoSizer onResize={this.setChartHeights} style={{ height: '100%', width: '100%' }}>
            {() => {
              return (
                <React.Fragment>
                  <HighchartsReact
                    key={'c1'}
                    ref={this.c1}
                    options={topConfig}
                    immutable={true} // as we are managing part of the config through Autosizer, the
                  />
                  <HighchartsReact key={'c2'} ref={this.c2} options={bottomConfig} immutable={true} />
                </React.Fragment>
              );
            }}
          </AutoSizer>
        </div>
      );
    } else {
      middleChartsEl = (
        <div style={{ display: 'block', overflow: 'auto' }} onWheel={this.onChartScroll}>
          <HighchartsReact key={'c1'} ref={this.c1} options={topConfig} immutable={true} />
          <HighchartsReact key={'c2'} ref={this.c2} options={bottomConfig} immutable={true} />
        </div>
      );
    }
    return (
      <div className={this.props.className} style={{ height: '100%', overflow: 'hidden' }}>
        <div
          onMouseDown={this.onSummaryMouseDown}
          onMouseUp={this.onSummaryMouseUp}
          onMouseMove={this.onSummaryMouseMove}
          style={{
            display: this.props.itemWidth ? 'block' : 'none',
          }}
        ></div>
        <div style={{ display: this.props.itemWidth ? 'block' : 'none' }}>
          <HighchartsReact key={'mini'} ref={this.mini} options={miniConfig} immutable={true} />
        </div>
        {middleChartsEl}
        <div style={{ width: '100%', height: imgBarHeight }}>
          <AutoSizer>
            {({ height, width }) => {
              const calcWidth = width - MARGIN * 2;
              const defaultWidth = Math.max(1, calcWidth / Math.max(1, this.props.data.length));
              return (
                <Grid
                  ref={(grid) => (this.grid = grid || undefined)}
                  height={height}
                  rowHeight={50}
                  rowCount={1}
                  width={calcWidth}
                  style={{
                    marginLeft: MARGIN + 'px',
                    marginRight: MARGIN + 'px',
                    outline: 'none',
                    overflow: 'auto',
                  }}
                  cellRenderer={this.cellRenderer}
                  columnCount={this.props.data.length}
                  columnWidth={Math.max(this.state.itemWidth || 0, defaultWidth)}
                  onScroll={this.onGridScroll}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    );
  }
}
